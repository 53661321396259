import { Hero } from '../components/Hero'
import { ProiectCard } from '../components/ProiectCard'
import imobileIcon from '../assets/icons/building.svg'
import etapeIcon from '../assets/icons/crane.svg'
import shopIcon from '../assets/icons/shop.svg'
import keysIcon from '../assets/icons/keys-2.svg'
import uuid from 'react-uuid'
import { AboutSection } from '../components/AboutSection'
import { Characteristics } from '../components/Characteristics'
import { StepSection } from '../components/StepSection'
import { MapSection } from '../components/MapSection'
import { Button } from '../components/Button'
import GoToTop from '../hooks/GoToTop'
import { Footer } from '../components/Footer'
import useMobile from '../hooks/useMobile'
import { AlteProiecte } from '../components/AlteProiecte'
import { Link } from 'react-router-dom'
import { FooterNote } from '../components/FooterNote'
import { Helmet } from 'react-helmet'

export const Proiect = () => {

    const mobile = useMobile()

    const cards = [
        {
            icon: imobileIcon,
            text: 'IMOBILE REZIDENTIALE',
            value: '13'
        },
        {
            icon: etapeIcon,
            text: 'ETAPE DE CONSTRUCTIE',
            value: '3'
        },
        {
            icon: keysIcon,
            text: 'STUDIOURI SI APARTAMENTE',
            value: '642'
        },
        {
            icon: shopIcon,
            text: 'SPATII COMPERCIALE',
            value: '1350'
        }
    ]

    return (
        <>
            <Helmet>
                <title>Apartamente noi Ploiești | VAMT | Rezidențial Vest</title>
                <meta name="description" content="Garsoniere, apartamente noi cu 2 și 3 camere de vânzare în zona vest Ploiești. Încălzire în pardoseală, zone comerciale, spații verzi și parcări subterane. Dezvoltat de VAMT." />
            </Helmet>
            
            <Hero />

            <div className="proiect__img">
                <div className="proiect__title">
                    <h1>
                        PROIECT
                    </h1>
                </div>
            </div>

            <div className="proiect">
                <div className="proiect__content">
                    <div className="container">
                        <div className="proiect__cards">
                            {cards.map((item) => (
                                <ProiectCard key={uuid()} card={item} />
                            ))}
                        </div>

                        <div className="proiect__button">
                            <Link to="/apartamente">
                                <Button text="Vezi Apartamente" />
                            </Link>
                        </div>

                        <AboutSection />
                    </div>

                    <Characteristics />

                    <StepSection />

                    <AlteProiecte />

                    <MapSection />
                    <div className="dt-hide mt-20 mb-20">
                        <div className="d-flex jc-center ai-center">
                            <Button text="VEZI PUNCTE DE INTERES" />
                        </div>
                    </div>

                </div>
                {mobile ? (
                    <FooterNote />
                ) : (
                    <Footer />
                )}
                <GoToTop />
            </div>
        </>
    )
}