import { ReactSVG } from 'react-svg'
import crane from '../assets/icons/crane.svg'

export const EtapaRow = ({ step, year }) => {
    return (
        <div className="etapa-row">
            <div className="etapa-row__icon">
                <ReactSVG src={crane} />
                <span className="etapa-row__icon-step">0{step}</span>
            </div>
            <div className="etapa-row__text">
                <h4>ETAPA {step} DE CONSTRUCTIE</h4>
                <h4>FINALIZARE {year}</h4>
            </div>
        </div>
    )
}