import { useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'

export const Breadcrumbs = () => {

    let { phase, rooms, type } = useParams()
    let location = useLocation()

    const [breadcrumbs, setBreadcrumbs] = useState(false)
    const [detailPage, setDetailPage] = useState(false)

    const formattedPhase = phase ? phase.replace('-', ' ') : null
    const formattedRooms = rooms ? rooms.replace('-', ' ') : null
    const formattedType = type ? type.replace('-', ' ') : null

    useEffect(() => {
        if (location.pathname.includes('/apartamente/')) {
            setBreadcrumbs(true)
        } else {
            setBreadcrumbs(false)
        }

        if (location.pathname.includes('/tip')) {
            setDetailPage(true)
        } else {
            setDetailPage(false)
        }
    }, [location.pathname])

    return (
        <>
            {breadcrumbs &&
                <div className="page-layout__breadcrumb">
                    <NavLink
                        className="page-layout__breadcrumb-item"
                        activeClassName="page-layout__breadcrumb-item--active"
                        to="/apartamente"
                    >
                        Apartamente
                    </NavLink>
                    <span>/</span>
                    <NavLink
                        className="page-layout__breadcrumb-item"
                        activeClassName="page-layout__breadcrumb-item--active"
                        to="/apartamente"
                    >
                        {formattedPhase}
                    </NavLink>
                    <span>/</span>
                    <NavLink
                        className={`page-layout__breadcrumb-item ${detailPage ? 'page-layout__breadcrumb-item--active' : ''}`}
                        to={`/apartamente/${phase}/${rooms}`}
                    >
                        {formattedRooms}
                    </NavLink>
                    {detailPage &&
                        <>
                            <span>/</span>
                            <NavLink
                                className="page-layout__breadcrumb-item"
                                to={`/apartamente/${phase}/${rooms}/${type}`}
                            >
                                {formattedRooms} {formattedType}
                            </NavLink>
                        </>
                    }
                </div>
            }
        </>
    )
}