const contactData = {
    address: 'Str. Targoviștei, Nr.12 Ploiești',
    whatsapp: 'https://api.whatsapp.com/send?phone=40738262767',
    phone_2: '0721 298 789',
    phone_1: '0738 262 767',
    email: 'vanzari@rezidentialvest.ro',
    facebook: 'https://www.facebook.com/casanouapentrutine'
}

export default contactData 
