import useMobile from '../hooks/useMobile'

export const AvailabilityValue = ({ disponibilitate, total }) => {

    const mobile = useMobile()
    
    return (
        <div className="apartamente__availability-value">
            <span>{`${(disponibilitate) !== 1 ? (disponibilitate) + (mobile ? ' disponibile' : ' apartamente disponibile') : (disponibilitate) + (mobile ? ' disponibil' : ' apartament disponibil')}`}</span>
            <span><span> | </span>{`${(total) > 1 ? 'din ' + (total) + (mobile ? ' total' : ' apartamente total') : 'dintr-un ' + (total) + (mobile ? ' total' : ' apartament total')}`}</span>
        </div>
    )
}