import { useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Apartament } from './pages/Apartament';
import { Apartamente } from './pages/Apartamente';
import { ApartamenteList } from './pages/ApartamenteList';
import { Contact } from './pages/Contact';
import { Finantare } from './pages/Finantare';
import { Galerie } from './pages/Galerie';
import { Proiect } from './pages/Proiect';
import { Prototype } from './pages/Prototype';
import './styles/styles.scss';
import smoothscroll from 'smoothscroll-polyfill'
import { initGA } from './utils/ga-utils'
import CookieConsent, {
  getCookieConsentValue, 
  Cookies,
} from "react-cookie-consent"
import ReactGA from 'react-ga'
import { PromotionButton } from './components/PromotionButton';

function App() {

  let location = useLocation()

  smoothscroll.polyfill()

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])


  useEffect(() => {
    const isConsent = getCookieConsentValue()
    if (isConsent === "true") {
      handleAcceptCookie()
    }
  }, [])

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
    }
  }

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga")
    Cookies.remove("_gat")
    Cookies.remove("_gid")
  }

  return (
    <>
      <CookieConsent
        //enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonStyle={{
          backgroundColor: '#00985B',
          color: 'white',
          borderRadius: '8px',
          fontWeight: '700',
          padding: '10px 16px',
        }}
        declineButtonStyle={{
          backgroundColor: 'white',
          color: '#00985B',
          borderRadius: '8px',
          fontWeight: '700',
          padding: '10px 16px',
        }}
        buttonText='Accept'
        declineButtonText='I decline'
      >
        Site-ul nostru foloseste cookie-uri. Continuand navigarea va exprimati acordul pentru folosirea cookie-urilor, asa cum este detaliat in politica noastra de confidentialitate.
      </CookieConsent>
      <Navbar />
 
      <div className="wrapper">
        <Switch>
          <Route exact path="/">
            <Proiect />
          </Route>

          <Route exact path="/finantare">
            <Finantare />
          </Route>

          <Route exact path="/apartamente">
            <Apartamente />
          </Route>

          <Route exact path="/apartamente/:phase/:rooms">
            <ApartamenteList />
          </Route>

          <Route path="/apartamente/:phase/:rooms/:type">
            <Apartament />
          </Route>

          <Route path="/galerie">
            <Galerie />
          </Route>

          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/prototype">
            <Prototype />
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default App;
