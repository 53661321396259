import uuid from 'react-uuid'
import { Table } from './Table'
import GoToTop from '../hooks/GoToTop'
import useMobile from '../hooks/useMobile'
import { Button } from './Button'
import ReactGA from 'react-ga';
import { useState } from 'react'
import Loader from 'react-loader-spinner'
import { AvailabilityValue } from './AvailabilityValue'
export const AptAvailability = ({ data, available, parter, etaj }) => {

    const { nr_camere, tip, faza } = data

    const [PDFLoading, setPDFLoading] = useState(false)

    const PDF_URL = `https://rezidentialvest.ro/vamt/excel.php?type=pdf&rooms=${nr_camere}&aptype=${tip}&phase=${faza}`

    const mobile = useMobile()

    const fetchPDF = () => {
        setPDFLoading(true)
        fetch(PDF_URL)
            .then(res => res.blob())
            .then(blob => {
                setPDFLoading(blob ? false : true)
            })
    }

    return (
        <>

            <div className="apartamente__availability">
                <h4>DISPONIBILITATE {!mobile && 'APARTAMENTE'}</h4>
                <h3>{nr_camere} {nr_camere.toLowerCase() !== 'studio' && 'camere'} {mobile && <br></br>}TIP {tip}</h3>
                <div className="text-center">
                    <AvailabilityValue disponibilitate={etaj[0].disponibilitate} total={etaj[0].total} />
                </div>

                {available && available.map(item_l1 => (
                    <div key={uuid()}>
                        {item_l1.cladire.map(item_l2 => (

                            <div key={uuid()} className="apartamente__availability-row">

                                {!mobile &&
                                    <div>
                                        <div className="apartamente__availability__img">
                                            <img src={item_l2.image} alt={`CLADIRE ${item_l2.cladire_type}`} />
                                        </div>


                                        <div className="table__button">
                                            <a onClick={fetchPDF} href={PDF_URL} download>
                                                <Button
                                                    action={() => ReactGA.event({
                                                        category: 'PDF Download',
                                                        action: JSON.stringify({ faza }) + JSON.stringify({ nr_camere }) + JSON.stringify({ tip }),
                                                    })}>

                                                    {PDFLoading ? (
                                                        <Loader
                                                            type="ThreeDots"
                                                            color="white"
                                                            width={50}
                                                            height={50}
                                                        />
                                                    ) : (
                                                        <>
                                                            Descarca PDF <i className="fas fa-download"></i>
                                                        </>
                                                    )}
                                                </Button>
                                            </a>
                                        </div>
                                    </div>
                                }
                                <div className="apartamente__availability__table">
                                    <div className="table__title">
                                        <h5>CLADIRE {item_l2.cladire_type}</h5>
                                    </div>
                                    {mobile &&
                                        <div className="apartamente__availability__img">
                                            <img src={item_l2.image} alt={`CLADIRE ${item_l2.cladire_type}`} />
                                        </div>
                                    }
                                    <Table
                                        faza={faza}
                                        nr_camere={nr_camere}
                                        tip={tip}
                                        headings={[
                                            'ETAJ',
                                            'S. construită\nTotal',
                                            'PRET',
                                        ]}
                                        values={item_l2.details.map(item_l3 => (
                                            {
                                                etaj: item_l3.etaj,
                                                utila: item_l3.suprafata_construita,
                                                pret: item_l3.price,
                                            }
                                        ))}

                                        pdf={`https://rezidentialvest.ro/vamt/excel.php?type=pdf&rooms=${nr_camere}&aptype=${tip}&phase=${faza}`}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <GoToTop /> 
        </>
    )
}
