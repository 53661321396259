import { useState, useEffect } from 'react'
import { PageLayout } from '../components/PageLayout'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { AptDetais } from '../components/AptDetails'
import { ImageBar } from '../components/ImageBar'
import { Button } from '../components/Button'
import { AptAvailability } from '../components/AptAvailability'
import { FloatMenu } from '../components/FloatMenu'
import GoToTop from '../hooks/GoToTop'
import { Loading } from '../components/Loading'
import useMobile from '../hooks/useMobile'
import ReactGA from 'react-ga';
import Loader from 'react-loader-spinner'

export const Apartament = () => {

    const [data, setData] = useState(null)
    const [available, setAvailable] = useState(null)
    const [parter, setParter] = useState([])
    const [etaj, setEtaj] = useState([])
    const [loading, setLoading] = useState(true)

    const { phase, rooms, type } = useParams()
    const mobile = useMobile()
    const history = useHistory()
    const location = useLocation()

    const [currentTab, setCurrentTab] = useState('DESCRIERE')

    useEffect(() => {
        if (location.pathname.includes('/disponibilitate')) {
            setCurrentTab('DISPONIBILITATE')
        } else {
            setCurrentTab('DESCRIERE')
        }

    }, [currentTab, location.pathname])


    const handleDescriereTab = () => {
        setCurrentTab('DESCRIERE')
        history.push({
            pathname: rooms.toLowerCase() === 'studio' ?
                `/apartamente/${phase}/studio/${type}` :
                `/apartamente/${phase}/${rooms}/${type}`
        })
        setTimeout(() => {
            scroll()
        }, 5)
    }

    const handleDisponibilitateTab = () => {
        setCurrentTab('DISPONIBILITATE')
        history.push({
            pathname: rooms.toLowerCase() === 'studio' ?
                `/apartamente/${phase}/studio/${type}/disponibilitate` :
                `/apartamente/${phase}/${rooms}/${type}/disponibilitate`
        })
        setTimeout(() => {
            scroll()
        }, 5)
    }

    const regex = /\d+/g

    const formattedRooms = rooms === 'studio' ? rooms : rooms.match(regex)[0].toString()
    const formattedType = type.split('-')[1]
    const formattedPhase = phase.match(regex)[0].toString()

    useEffect(() => {
        const ac = new AbortController()
        const signal = ac.signal
        const url = `https://rezidentialvest.ro/vamt/excel.php?type=details&rooms=${formattedRooms}&aptype=${formattedType}&phase=${formattedPhase}`

        const fetchData = async () => {
            try {
                const response = await fetch(url, { signal: signal })
                const json = await response.json()
                setData(json.filter(item => (item.nr_camere.toLowerCase() === formattedRooms) && item.tip === formattedType && item.parter_sau_etaj === "Etaj")[0])
                setParter(json.filter(type => type.parter_sau_etaj.trim() === 'Parter'))
                setEtaj(json.filter(type => type.parter_sau_etaj.trim() === 'Etaj'))
            } catch (error) {
                console.log("error", error)
            }
        }
        fetchData()

        return () => ac.abort()
    }, [formattedPhase, formattedRooms, formattedType])

    useEffect(() => {
        const ac = new AbortController()
        const signal = ac.signal

        const url = `https://rezidentialvest.ro/vamt/excel.php?type=availability&rooms=${formattedRooms}&aptype=${formattedType}&phase=${formattedPhase}`

        const fetchData = async () => {
            try {
                const response = await fetch(url, { signal: signal })
                const json = await response.json()
                setAvailable(json)
            } catch (error) {
                console.log("error", error)
            }
        }
        fetchData()

        return () => ac.abort()
    }, [formattedPhase, formattedRooms, formattedType])

    useEffect(() => {
        if (data === null) {
            setLoading(true)
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }
    }, [data])
    function scroll() {
        var offset = 0
        if (mobile) {
            offset = 230
        } else {
            offset = 480
        }

        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        scroll()

    })


    const [PDFLoading, setPDFLoading] = useState(false)

    const PDF_URL = `https://rezidentialvest.ro/vamt/excel.php?type=pdf&rooms=${formattedRooms}&aptype=${formattedType}&phase=${formattedPhase}`

    const fetchPDF = () => {
        setPDFLoading(true)
        fetch(PDF_URL)
            .then(res => res.blob())
            .then(blob => {
                setPDFLoading(blob ? false : true)
            })
    }
    return (
        <>
            <PageLayout
                title={`${(formattedRooms === 'studio') ? ('Studio TIP ') : ('' + formattedRooms + ' Camere Tip ')}${formattedType}`}
                description={`${(formattedRooms === 'studio') ? ('Garsoniera, apartament tip Studio') : ('Apartament ' + formattedRooms + ' camere')} nou de vanzare. Încălzire în pardoseală, zone comerciale, spații verzi și parcări subterane`}
                noContainer
            >
                {loading ? (
                    <div className="container">
                        <Loading />
                    </div>
                ) : (
                    <>
                        <div className={mobile ? '' : 'container'}>
                            <FloatMenu
                                currentTab={currentTab}
                                items={[
                                    {
                                        name: 'DESCRIERE',
                                        action: handleDescriereTab
                                    },
     
                                ]}
                            />

                            {currentTab === 'DESCRIERE' && (
                                <AptDetais data={data} etaj={etaj} parter={parter} />
                            )}

                            {currentTab === 'DISPONIBILITATE' && (
                                <AptAvailability available={available} data={data} etaj={etaj} parter={parter} />
                            )}

                        </div>
                        {currentTab === 'DESCRIERE' && (
                            <>
                                <ImageBar
                                    images={etaj.length > 0 && etaj[0].imgs} 
                                />
                                <div className="apartamente__details__prices">
                                    <div className="container">
                                        <div className="apartamente__details__prices-row">
                                            {etaj.length > 0 &&
                                                <div className="apartamente__details__prices-col">
                                                    <h5>
                                                        {etaj[0].nr_camere} {etaj[0].nr_camere.toLowerCase() !== 'studio' && 'camere'} TIP {etaj[0].tip}
                                                    </h5>

                                                    <span className="text--500">de la</span>
                                                    <h6 className="price">{etaj[0].price}<span>€</span></h6>
                                                    <span className="text--500">+TVA</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="table__button">
                                            <a onClick={fetchPDF} href={PDF_URL} download>
                                                <Button
                                                    action={() => ReactGA.event({
                                                        category: 'PDF Download',
                                                        action: JSON.stringify({ formattedPhase }) + JSON.stringify({ formattedRooms }) + JSON.stringify({ formattedType }),
                                                    })}>

                                                    {PDFLoading ? (
                                                        <Loader
                                                            type="ThreeDots"
                                                            color="white"
                                                            width={50}
                                                            height={50}
                                                        />
                                                    ) : (
                                                        <>
                                                            Descarca PDF <i className="fas fa-download"></i>
                                                        </>
                                                    )}
                                                </Button>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </> 
                        )}
                    </>

                )}
                <GoToTop />
            </PageLayout>
        </>
    )
}
