import Moment from 'react-moment'
import 'moment/locale/ro'
import FbImageLibrary from 'react-fb-image-grid'

export const GalerieCard = ({ text, date, images }) => {

    return (
        <div className="galerie__card">
            <div className="galerie__card__date">
                <Moment locale="ro" format="D MMM YYYY">{date}</Moment>
            </div>
            <div className="galerie__card__text">
                {text}
            </div>

            <div className="galerie__card__images">
                <FbImageLibrary images={images} hideOverlay={true} />
            </div>

        </div>
    )
}