import { useState, useLayoutEffect, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import uuid from 'react-uuid';
import Scrollspy from 'react-scrollspy'
import useScrollPosition from "../hooks/useScrollPosition"
import { Breadcrumbs } from './Breadcrumbs'
import useMobile from '../hooks/useMobile';

export const FloatMenu = ({ items, currentTab, scrollspyOffset }) => {
    const mobile=useMobile()
    const [float, setFloat] = useState(false)
    const [breadcrumbsVisible, setBreadcrumbsVisible] = useState(false)

    const scrollPos = useScrollPosition()
    let location = useLocation()
    const history = useHistory()

    useLayoutEffect(() => {
        if (location.pathname.includes('/apartament')) {

            if (window.innerWidth > 1023) {

                if (scrollPos > 420) {
                    setFloat(true)
                    return () => {
                        setFloat(false)
                    }
                }
            } else {
                if (scrollPos > 210) {
                    setFloat(true)
                    return () => {
                        setFloat(false)
                    }
                }
            }
        }

    }, [float, location.pathname, scrollPos])

    useLayoutEffect(() => {
        if (location.pathname.includes('/galerie')) {

            if (window.innerWidth > 1023) {

                if (scrollPos > 420) {
                    setFloat(true)
                    return () => {
                        setFloat(false)
                    }
                }
            } else {
                if (scrollPos > 210) {
                    setFloat(true)
                    return () => {
                        setFloat(false)
                    }
                }
            }
        }

    }, [float, location.pathname, scrollPos])

    useEffect(() => {
        if (location.pathname.includes('/apartamente/')) {
            if (window.innerWidth < 1024) {
                if (scrollPos > 210) {
                    setBreadcrumbsVisible(true)
                } else {
                    setBreadcrumbsVisible(false)
                }
            }
        }
    }, [scrollPos, location.pathname])


    useEffect(() => {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault()

                const element = document.querySelector(this.getAttribute('href'))
                var offset = 0
                if (location.pathname.startsWith('/apartamente/faza')) {
                    if (float) {
                        if (mobile) {
                            offset = 110
                        }
                        else {
                            offset = 60
                        }
                    }
                    else {
                        if (mobile) {
                            offset = 165
                        }
                        else {
                            offset = 120
                        }

                    }
                }
                else {
                    if (float) {
                        if (mobile) {
                            offset = 55
                        }
                        else {
                            offset = 60
                        }
                    }
                    else {
                        if (mobile) {
                            offset = 160
                        }
                        else {
                            offset = 195
                        }

                    }
                }
                const bodyRect = document.body.getBoundingClientRect().top
                const elementRect = element.getBoundingClientRect().top
                const elementPosition = elementRect - bodyRect
                const offsetPosition = elementPosition - offset

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                })
            })
        })
    })

    return (
        items && (
            <div className={`float-menu ${float ? 'floating' : ''}`}>
                {breadcrumbsVisible &&
                    <div className="breadcrumbs">
                        <span onClick={() => history.goBack()}><i className="far fa-arrow-left"></i></span>
                        <Breadcrumbs />
                    </div>
                }
                <Scrollspy
                    items={items.map(item => item.href)}
                    currentClassName="is-active"
                    offset={scrollspyOffset || 0}
                >
                    {items.map(item => (
                        <li key={uuid()} className={currentTab && currentTab === item.name ? 'tab-active' : ''}>
                            {item.href && (
                                <a
                                    href={`#${item.href}`}
                                    className="float-menu__item"
                                >
                                    {item.name}
                                </a>
                            )}
                            {item.action && (
                                <span onClick={item.action}>{item.name}</span>
                            )}
                        </li>
                    ))}
                </Scrollspy>

            </div>
        )
    )
}
