import { useEffect, useState } from 'react'
import { PageLayout } from '../components/PageLayout'
import uuid from 'react-uuid'
import GoToTop from '../hooks/GoToTop'
import { EtapaRow } from '../components/EtapaRow'
import { FloatMenu } from '../components/FloatMenu'
import { Loading } from '../components/Loading'
import { GalerieCard } from '../components/GalerieCard'
import { useHistory } from 'react-router'
import useMobile from '../hooks/useMobile'

export const Galerie = () => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [currentTab, setCurrentTab] = useState(null)

    const history = useHistory()
    const mobile = useMobile()

    useEffect(() => {
        const ac = new AbortController()
        const signal = ac.signal
        const url = `https://rezidentialvest.ro/vamt/excel.php?type=gallery`

        const fetchData = async () => {
            try {
                const response = await fetch(url, { signal: signal })
                const json = await response.json()
                setData(json)
                setCurrentTab(`Etapa ${json[0].faza}`)
            } catch (error) {
                console.log("error", error)
            }
        }
        fetchData()

        return () => ac.abort()
    }, [])



    useEffect(() => {
        if (data === null) {
            setLoading(true)
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }
    }, [data])

    useEffect(() => {
        if (document.querySelector('.page-layout').classList.contains('page-layout--gray')) {
            document.body.style.background = '#F8F9FB'
        } else {
            document.body.style.background = 'white'
        }

        return () => {
            document.body.style.background = 'white'
        }
    })

    const handleTab = (faza) => {
        setCurrentTab(`Etapa ${faza}`)
        history.push({
            pathname: `/galerie/etapa-${faza}`
        })
        setTimeout(() => {
            scroll()
        }, 5)
    }

    function scroll() {
        var offset = 0
        if (mobile) {
            offset = 230
        } else {
            offset = 480
        }

        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        scroll()

    })

    return (
        <PageLayout title='Galerie' description={'Galerie'}>

            {loading ? (
                <Loading />
            ) : (
                <div>
                    <FloatMenu
                        currentTab={currentTab}
                        achorOffset={-80}
                        scrollspyOffset={-600}
                        items={data && data.map(item => (
                            {
                                name: `Etapa ${item.faza}`,
                                action: () => handleTab(item.faza)
                            }
                        ))}
                    />

                    {data && data.map((item, i) => (
                        <>
                            {currentTab === `Etapa ${item.faza}` && (
                                <div key={uuid()} id={`etapa-${item.faza}`} className="apartamente__row">
                                    <EtapaRow step={item.faza} year={item.year} />
                                    <div className="galerie">
                                        {item.details.map((apt, index) => (

                                            <GalerieCard
                                                key={uuid()}
                                                text={apt.description}
                                                date={apt.date}
                                                images={apt.images}
                                            />

                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    ))}

                </div>
            )}
            <GoToTop />
        </PageLayout>
    )
}