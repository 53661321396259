import { Carousel } from 'react-carousel-minimal'
import hero1 from '../assets/images/hero1.jpg'
import hero2 from '../assets/images/hero2.jpg'
import hero3 from '../assets/images/hero3.jpg'

export const Hero = () => {

    const images = [
        {
            image: hero1
        },
        {
            image: hero2
        },

    ]

    return (
        <>
            <div className="hero">
                <Carousel
                    data={images}
                    width="100%"
                    height="788px"
                    automatic={true}
                    time={4000}
                    slideImageFit="cover"
                />
            </div>
        </>
    )
}
