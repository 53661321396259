import room from '../assets/icons/room.svg'
import balcony from '../assets/icons/balcony.svg'
import plans from '../assets/icons/plans.svg'
import bathing from '../assets/icons/bathing.svg'
import { Button } from './Button'
import { useHistory } from 'react-router-dom'
import { InfoIconBadge } from './InfoIconBadge'
import useMobile from '../hooks/useMobile'
import { AvailabilityValue } from './AvailabilityValue'

export const AptDetailCard = ({ data, faza }) => {
    function isNumeric(num){
        return !isNaN(num)
      }
      const { nr_camere, numar_bai,  disponible, image, tip, suprafata_construita,  numar_balcoane,  price, total} = data


    const history = useHistory()

    const mobile = useMobile()

    const redirect = () => {
        if (nr_camere.toLowerCase() === 'studio') {
            history.push(`/apartamente/faza-${faza}/studio/tip-${tip}`)
        } else {
            history.push(`/apartamente/faza-${faza}/${nr_camere}-camere/tip-${tip}`)
        }
    }

    return (
        <div className="apartamente__detail-card">
            <div className="apartamente__detail-card__wrapper">
                {!mobile &&
                    <div className="apartamente__detail-card__img">
                        <img src={image} alt="plan" />
                    </div>
                }
                <div className="apartamente__detail-card__info">
                    {mobile ? (
                        <h3>{nr_camere} {nr_camere.toLowerCase() !== 'studio' && 'camere'} Tip {tip}</h3>
                    ) : (
                        <>
                            <h4>Apartament {nr_camere} {nr_camere.toLowerCase() !== 'studio' && 'camere'}</h4>
                            <h3>Tip {tip}</h3>
                        </>
                    )}

                    <AvailabilityValue disponibilitate={disponible} total={total} />
                    {mobile &&
                        <div className="apartamente__detail-card__info-mb-img">
                            <img src={image} alt="plan" />
                        </div>
                    }

                    <div className="apartamente__detail-card__info__icons">
                        {mobile ? (
                            <>
                                <InfoIconBadge icon={room} text={`${nr_camere} ${nr_camere.toLowerCase() !== 'studio' ? 'camere' :''}`} />
                                <InfoIconBadge icon={plans} text={`${suprafata_construita} mpc`} />
                                <InfoIconBadge icon={balcony} text={`${numar_balcoane} balcon`} />
                                <InfoIconBadge icon={bathing} text={`${numar_bai} ${nr_camere.toLowerCase() !== '3' ? 'baie' :'bai'}`} />
                            </>
                        ) : (
                            <>
                                <InfoIconBadge icon={room} text={`${nr_camere} ${nr_camere.toLowerCase() !== 'studio' ? 'camere' :''}`} />
                                <InfoIconBadge icon={plans} text={`${suprafata_construita} mpc`} />
                                <InfoIconBadge icon={balcony} text={`${numar_balcoane} balcon`} />

                            </>
                        )}

                    </div>

                    <div className="apartamente__detail-card__info__area">
                        <div>
                            <span className="text--bold">Suprafață construită: </span><span>{suprafata_construita} mp</span>
                        </div>
                    </div>
                    {isNumeric(price) ?
            (<>
                    <div className="apartamente__detail-card__info__price">
                    <span>de la</span>
                        <h6 className="price">{parseInt(price).toLocaleString()}<span>€</span></h6>
                        <span>+ TVA</span>
                    </div>
                    <Button key={0} text="Detalii" action={redirect} /></>
                   
            ):(
                
                   <div className="apartamente__detail-card__info__price">
                <h4 className="price">{price}</h4>
                </div>
                    
                
             

                
            )
}

                    
                </div>
            </div>
        </div>
    )
}
