import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import { AptDetailCard } from '../components/AptDetailCard'
import { FloatMenu } from '../components/FloatMenu'
import { Loading } from '../components/Loading'
import { PageLayout } from '../components/PageLayout'
import GoToTop from '../hooks/GoToTop'
import useMobile from '../hooks/useMobile';
export const ApartamenteList = () => {
    const mobile = useMobile()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    let { rooms, phase } = useParams()

    const regex = /\d+/g

    const formattedRooms = rooms === 'studio' ? rooms : rooms.match(regex)[0].toString()
    const formattedPhase = phase.match(regex)[0].toString()

    useEffect(() => {
        const ac = new AbortController()
        const signal = ac.signal
        const url = `https://rezidentialvest.ro/vamt/excel.php?type=types&rooms=${formattedRooms}&phase=${formattedPhase}`

        const fetchData = async () => {
            try {
                const response = await fetch(url, { signal: signal })
                const json = await response.json()
                setData(json)
            } catch (error) {
                console.log("error", error)
            }
        }
        fetchData()

        return () => ac.abort()
    }, [formattedPhase, formattedRooms])

    useEffect(() => {
        if (data === null) {
            setLoading(true)
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }
    }, [data])

    useEffect(() => {
        if (document.querySelector('.page-layout').classList.contains('page-layout--gray')) {
            document.body.style.background = '#F8F9FB'
        } else {
            document.body.style.background = 'white'
        }

        return () => {
            document.body.style.background = 'white'
        }
    })
    function scroll() {
 
        var offset = 0
        if (mobile) {
            offset = 230
        } else {
            offset = 575
        }

        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        })
    }
    scroll()
    return (

        <PageLayout
            title={`${(formattedRooms==='studio')?('Apartamente Studio'):('Apartamente '+formattedRooms+' camere')}`}
            description={`${(formattedRooms==='studio')?('Apartamente tip Studio, Garsoniere'):('Apartamente '+formattedRooms+' camere')} noi de vanzare. Încălzire în pardoseală, zone comerciale, spații verzi și parcări subterane`}
            gray
        > 

            {loading ? (
                <Loading />
            ) : (
                <>
                    <FloatMenu
                        achorOffset={-200}
                        scrollspyOffset={-400}
                        items={data && data.map(item => (
                            {
                                name: `Tip ${item.tip}`,
                                href: `tip-${item.tip}`
                            }
                        ))}
                    />
                    <div className="apartamente__list">
                        {data && data.filter(item => item.nr_camere.toLowerCase() === formattedRooms).map((filteredData) => (
                            <div key={uuid()} id={`tip-${filteredData.tip}`} >
                                <AptDetailCard data={filteredData} faza={formattedPhase} />
                            </div>
                        ))}
                    </div>
                </>
            )}
            <GoToTop />
        </PageLayout>
    )
}
