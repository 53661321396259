import svg from '../assets/images/cards-svg.svg'
import { Button } from './Button'
import { useHistory } from 'react-router-dom'
import { AvailabilityValue } from './AvailabilityValue'

export const AptCard = ({ data, faza }) => {
    function isNumeric(num){
        return !isNaN(num)
      }
    const { nr_camere, price, disponibilitate, total } = data

    let history = useHistory();

    const redirect = () => {
        if (nr_camere.toLowerCase() === 'studio') {
            history.push(`apartamente/faza-${faza}/studio`) 
        } else {
            history.push(`apartamente/faza-${faza}/${nr_camere}-camere`)
        }
    }

    return (
        <div className="apartamente__card">
            <div>
                <h4>Apartamente</h4>
                {nr_camere &&
                    <h3>{nr_camere} {nr_camere.toLowerCase() !== 'studio' && 'camere'}</h3>
                }
                <AvailabilityValue disponibilitate={disponibilitate} total={total} />
                <div className="apartamente__card__svg">
                    <img src={svg} alt="svg shape" />
                </div>
            </div>
            {isNumeric(price) ?
            (
                <div className="apartamente__card__price">
                    <span>Incepand de la</span>
                    <h6 className="price">{parseInt(price).toLocaleString()}<span>€</span></h6>
                    <span>+TVA</span>
                </div>
            ): (

                <div className="apartamente__card__price">
                <h6 className="price">{price}</h6>
            </div>
            )
            }
            <Button text="Detalii" action={redirect} />
        </div>
    )
}
