import { SectionTitle } from './SectionTitle'
import image_1 from '../assets/images/vamt-imobiliare-paulesti.png'
import image_2 from '../assets/images/vamt-imobiliare.png'
import pdf_1 from '../assets/pdf/VAMT-Marasesti.pdf'
import pdf_2 from '../assets/pdf/VAMT-Magnolia.pdf'

export const AlteProiecte = () => {
    return (
        <div className="alte-proiecte">
            <div className="container">
                <SectionTitle title_1="Vamt" title_2="Alte Proiecte" />
                <div className="alte-proiecte__content">
                    <a download href={pdf_1}>
                        <img src={image_2} alt="vamt imobiliare paulesti" />
                        <span>VAMT MARAȘESTI 118</span>
                    </a>
                </div>
            </div>
        </div>
    )
}