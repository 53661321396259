import { useState, useEffect } from 'react'
import { AptCard } from '../components/AptCard'
import { PageLayout } from '../components/PageLayout'
import { EtapaRow } from '../components/EtapaRow'
import uuid from 'react-uuid'
import { FloatMenu } from '../components/FloatMenu'
import GoToTop from '../hooks/GoToTop'
import { Loading } from '../components/Loading'
import useMobile from '../hooks/useMobile';
export const Apartamente = () => {
    const mobile = useMobile()

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const ac = new AbortController()
        const signal = ac.signal
        const url = `https://rezidentialvest.ro/vamt/excel.php?type=rooms`

        const fetchData = async () => {
            try {
                const response = await fetch(url, { signal: signal })
                const json = await response.json()
                setData(json)
            } catch (error) {
                console.log("error", error)
            }
        }
        fetchData()

        return () => ac.abort()
    }, [])

    useEffect(() => {
        if (data === null) {
            setLoading(true)
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }
    }, [data])


    useEffect(() => {
        if (document.querySelector('.page-layout').classList.contains('page-layout--gray')) {
            document.body.style.background = '#F8F9FB'
        } else {
            document.body.style.background = 'white'
        }

        return () => {
            document.body.style.background = 'white'
        }
    })
   function scroll() {
       
        var offset = 0
        if (mobile) {
            offset = 230
        } else {
            offset = 480
        }

        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        })
    }
    scroll()
    return (
        <>
            <PageLayout title="Apartamente" gray description={'Studio-uri, apartamente noi cu 2 și 3 camere de vânzare în zona vest Ploiești. Încălzire în pardoseală, zone comerciale, spații verzi și parcări subterane'}>  
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <FloatMenu
                            achorOffset={-80}
                            scrollspyOffset={-600}
                            items={data && data.map(item => (
                                {
                                    name: `Etapa ${item.faza}`,
                                    href: `etapa-${item.faza}` 
                                }
                            ))}
                        />

                        {data && data.map((item, i) => (
                            <div key={uuid()} id={`etapa-${item.faza}`} className="apartamente__row">
                                <EtapaRow step={item.faza} year={item.year} />
                                <div className="apartamente__cards">
                                    {item.details.map(apt => (
                                        <AptCard key={uuid()} data={apt} faza={item.faza} /> 
                                    ))}
                                </div>
                            </div>
                        ))}
                    </>
                )}
                <GoToTop />
            </PageLayout>
        </>
    )
}
